import Logo from "../assets/knowlage_hub_daily_banner.png";
import TopMenu from "./TopMenu";
import Footer from "./Footer";

const Contact = () => {
    return (
        <div>
            <TopMenu back_image={Logo}/>
            <div className='footer-title'>
                <span>CONTACT US!</span>
            </div>
            <div className='contact-container'>
                <p>
                    Want to get in touch with us?
                    Feel free to send us a message using the details below.
                </p>
                <p>
                   Email : emailus@onlineleonz.com
                </p>
                <p>
                    Address: Georgia, Samtredia District, City Council Gamochinebuli
                </p>
            </div>
            <Footer/>
        </div>
    );
}

export default Contact