import '../styles/article.css';
import Logo from "../assets/knowlage_hub_daily_banner.png";
import {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {getPost} from "../api/api";
import Content from "./Content";
import TopMenu from "./TopMenu";
import Footer from "./Footer";

const Article = (props) => {
    const [post, setPost] = useState('')
    const {id} = useParams()
    const navigate = useNavigate();

    const DEBUG = false;
    let path = "https://knowledgehubdaily.com";
    if (DEBUG) {
        path = "http://localhost:3000";
    }

    useEffect(()=>{
        var url = new URL(window.location.href);
        if (url.searchParams.has("red")){
            let redUrl = window.location.href.split("?red=")[1];
            setTimeout(function() {
                window.location.href = decodeURIComponent(redUrl);
            }, 200);
        } else {
            (async ()=>{
                try {
                    const info = await getPost(id)
                    setPost(info)
                }
                catch (e){
                    console.log(e);
                    navigate('*');
                }
            })();
        }
    },[id , navigate])


   const RelatedTopics = () =>
   {
       const handleError = (event) => {
           let a = event.target;
           var cfidpWrapper = document.createElement('div');
           a.parentNode.insertBefore(cfidpWrapper, a);
           cfidpWrapper.setAttribute('id', 't3adc7b1');
           var s = document.createElement('script');
           s.src=`https://www.idp-cf.com/tag?gd=AP1007178&kw=${post.category}&cType=1&tagId=t3adc7b1`;
           s.async=true;
           a.parentNode.insertBefore(s, a);
       }
    return (
       <img src="data:image/png,cf" style={{ display: 'none' }} onError={handleError}/>)

    }
       // <div className="topics-container">
       //  <span><b> Related Topics (Ads) : </b></span>
       //  <div className="topics-bullets-container">
       //      <div className="topics-bullet" href="">
       //          <span>1</span>
       //          <span>article title</span>
       //          <span className="arrow right"></span>
       //      </div>
       //      <div className="topics-bullet" href="">
       //          <span>2</span>
       //          <span>article title</span>
       //          <span className="arrow right"></span>
       //      </div>
       //      <div className="topics-bullet" href="">
       //          <span>3</span>
       //          <span>article title</span>
       //          <span className="arrow right"></span>
       //      </div>
       //      <div className="topics-bullet" href="">
       //          <span>4</span>
       //          <span>article title</span>
       //          <span className="arrow right"></span>
       //      </div>
       //  </div>
       // </div>

    return (
        <div>
            <TopMenu back_image={Logo}/>
            <Content>
                <div className='full-article-container'>
                    <img className='image' src={`${path}/posts-images/${post.image}`} alt="null" fetchpriority="high"/>
                    <div className='container'>
                        <div dangerouslySetInnerHTML={{__html:post.html1}}></div>
                        <RelatedTopics/>
                        <div dangerouslySetInnerHTML={{__html:post.html2}}></div>

                    </div>

                </div>
            </Content>
            <Footer/>
        </div>
    );
}

export default Article